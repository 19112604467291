import { OnInit } from '@angular/core';
import { EfilingService } from '../services/efiling.service';
var DailyReconcilationComponent = /** @class */ (function () {
    function DailyReconcilationComponent(efilingService) {
        this.efilingService = efilingService;
        this.styles = {};
        this.curYear = 2020;
        this.prevYear = this.curYear - 1;
    }
    DailyReconcilationComponent.prototype.intializeMonths = function () {
        this.months = [
            { id: 1, name: 'JAN', status: 'deactive' },
            { id: 2, name: 'FEB', status: 'deactive' },
            { id: 3, name: 'MAR', status: 'deactive' },
            { id: 4, name: 'APR', status: 'deactive' },
            { id: 5, name: 'MAY', status: 'deactive' },
            { id: 6, name: 'JUN', status: 'deactive' },
            { id: 7, name: 'JUL', status: 'deactive' },
            { id: 8, name: 'AUG', status: 'deactive' },
            { id: 9, name: 'SEP', status: 'deactive' },
            { id: 10, name: 'OCT', status: 'deactive' },
            { id: 11, name: 'NOV', status: 'deactive' },
            { id: 12, name: 'DEC', status: 'deactive' },
        ];
    };
    DailyReconcilationComponent.prototype.ngOnInit = function () {
        this.color = 'red';
        this.styles = { 'backgroundColor': 'deactive', 'width': '30px', 'height': '30px', 'border': 'none' };
        this.intializeMonths();
        this.GetReportData(this.curYear);
    };
    DailyReconcilationComponent.prototype.GetReportData = function (year) {
        var _this = this;
        debugger;
        this.efilingService.GetReportData(year)
            .subscribe(function (result) {
            if (result.length != 0) {
                var _loop_1 = function (i) {
                    if (result[i].status) {
                        a = _this.months.find(function (x) { return x.id == result[i].month; });
                        a.status = "green";
                    }
                    else {
                        a = _this.months.find(function (x) { return x.id == result[i].month; });
                        a.status = "red";
                    }
                };
                var a, a;
                // as EFilingDetailsDTO;
                for (var i = 0; i < result.length; i++) {
                    _loop_1(i);
                }
            }
            else {
                _this.intializeMonths();
            }
            _this.reportData = _this.months;
        }, function (error) { return console.error(error); });
    };
    DailyReconcilationComponent.prototype.curYearChange = function () {
        this.curYear = this.prevYear;
        this.prevYear = this.prevYear + 1;
        this.GetReportData(this.curYear);
    };
    DailyReconcilationComponent.prototype.prevYearChange = function () {
        this.GetReportData(this.curYear);
        this.curYear = this.prevYear;
        this.prevYear = this.prevYear - 1;
    };
    return DailyReconcilationComponent;
}());
export { DailyReconcilationComponent };
var Months = /** @class */ (function () {
    function Months() {
    }
    return Months;
}());
export { Months };
var ResultDto = /** @class */ (function () {
    function ResultDto() {
    }
    return ResultDto;
}());
export { ResultDto };
