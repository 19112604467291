

<nav class="navbar navbar-default navbar-static-top" role="navigation" style="margin-bottom: 0" *ngIf="Auth.visible">
  <div class="navbar-header">
    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"></button>
    <a class='navbar-brand' routerLink='home'>
      <span><img src="../../assets/images/logo_02.jpg" style="width:230px;height:30px;" alt="" /></span>
    </a>
    <div class="navbar-brand">
      <a id="menu-toggle" (click)="toggleMenu()" class="glyphicon glyphicon-align-justify btn-menu toggle">
      </a>
    </div>
  </div>
  <!-- /.navbar-header -->
  <ul class="nav navbar-top-links navbar-right">
    <!-- /.dropdown -->
    <li class="dropdown">
      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
        <i class="fa fa-user fa-fw"></i> <i class="fa fa-caret-down"></i>
      </a>
      <ul class="dropdown-menu dropdown-user">
        <li>
          <a routerLink="/"><i class="fa fa-sign-out fa-fw"></i> Logout</a>
        </li>
      </ul>
      <!-- /.dropdown-user -->
    </li>
    <!-- /.dropdown -->
  </ul>
  <!-- /.navbar-top-links -->
  <!--<div style="clear:both"><i class="fas fa-bars"></i></div>-->
  <div id="sidebar-wrapper" class="navbar-default sidebar" role="navigation">
    <div class="sidebar-nav navbar-collapse">
      <ul class="nav" id="side-menu">
        <li class="sidebar-search" style="display:none;">
          <div class="input-group custom-search-form">
            <input type="text" class="form-control" placeholder="Search...">
            <span class="input-group-btn">
              <button class="btn btn-default" type="button">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
          <!-- /input-group -->
        </li>
        <li class="sidebar-search" style="display:none;">
          <div class="input-group custom-search-form">
            <input type="text" class="form-control" placeholder="Search...">
            <span class="input-group-btn">
              <button class="btn btn-default" type="button">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </li>
        <li>
        <li [routerLinkActive]="['active']" [routerLinkActiveOptions]='{ exact: true }'>
          <a routerLink='home' routerLinkActive="active" (click)='collapse()'><i class="glyphicon glyphicon-home"></i> Home</a>
        </li>
        <li [routerLinkActive]="['active']" [routerLinkActiveOptions]='{ exact: true }'>
          <a routerLink='Searches' routerLinkActive="active" (click)='collapse()'><i class="glyphicon glyphicon-search"></i> eFiling</a>
        </li>
        <li  data-toggle="collapse" data-target="#list" >
          <a  (click)='arrowChange()'><i class="fa fa-usd">&nbsp;&nbsp;</i>Accounting<span  style="padding-right:100px;"></span><span  *ngIf="down" (click)='arrowChange()' class="glyphicon glyphicon-triangle-bottom"></span><span  *ngIf="up"  (click)='arrowChange()' class="glyphicon glyphicon-triangle-right"></span> </a>
          <ul class="nav" id="list" style="padding-left:30px;" *ngIf="isExpanded">
            <li [routerLinkActive]="['active']">
            <a routerLink='daily-reconcilation'  class="collapse" routerLinkActive="active" >Daily Reconcilation</a></li>
            <li>
            <a routerLink='RevenueReport' routerLinkActive="active">Revenue Report</a></li>
          
          </ul>
        </li>
        <li [routerLinkActive]="['active']" [routerLinkActiveOptions]='{ exact: true }'>
          <a routerLink='#' routerLinkActive="active" (click)='collapse()'><i class="glyphicon glyphicon-user"></i> User</a>
        </li>
        <!--<li [routerLinkActive]="['active']" [routerLinkActiveOptions]='{ exact: true }'>
    <a routerLink='#' routerLinkActive="active" (click)='collapse()'><i class="glyphicon glyphicon-folder-open"></i> Web EFiling</a>
  </li>
  <li [routerLinkActive]="['active']" [routerLinkActiveOptions]='{ exact: true }'>
    <a routerLink='#' routerLinkActive="active" (click)='collapse()'><i class="glyphicon glyphicon-folder-open"></i> Smart Form EFiling</a>
  </li>
  <li [routerLinkActive]="['active']" [routerLinkActiveOptions]='{ exact: true }'>
    <a routerLink='#' routerLinkActive="active" (click)='collapse()'><i class="glyphicon glyphicon-usd"></i> Transaction Payment</a>
  </li>
  <li [routerLinkActive]="['active']" [routerLinkActiveOptions]='{ exact: true }'>
    <a routerLink='#' routerLinkActive="active" (click)='collapse()'><i class="glyphicon glyphicon-stats"></i> Daily Settlement Report</a>
  </li>-->
      </ul>
    </div>
    <!-- /.sidebar-collapse -->
  </div>
  <!-- /.navbar-static-side -->

</nav>



