
<head>
  <!--<meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1">-->
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
  <!--<script src="https://a.alert-successjax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>-->
  <style>
    .alert-success {
      background-color: lightsteelblue !important;
      border-color: lightsteelblue !important;
    }
  </style>
</head>
<body>
  <div class="container-fluid">
    <div class="navbar-menu" style="height: 63px;">
      <div class="row">
        <div class="col-md-12">
          <!--<marquee behavior="scroll" direction="left">-->
          <span><img src="../../assets/images/logo_02.jpg" style="width:294px;height:47px;" alt="" /><span style="float:right;font-size:medium;padding-top:10px"><span style="color:green;" class="blink_me"><b> {{env}}</b></span></span></span>
          <!--</marquee>-->
        </div>
      </div>
    </div>
    <div class="alert alert-success">
      <div class="login-form ">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <h2 class="text-center" style="padding-bottom:25px;"><span><img src="../../assets/images/logo.png" /></span></h2>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <input type="text" class="form-control" autocomplete="off" autofocus name="username" formControlName="userName" placeholder="Username" required="required">
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-lock"></i></span>
              <input type="password" class="form-control" name="password" formControlName="passwordHash" placeholder="Password" required="required">
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary login-btn btn-block">Sign in</button>
          </div>
          <div class="clearfix">
            <label class="pull-left checkbox-inline"><input type="checkbox"> Remember me</label>
            <a (click)="AddNewuser();" class="pull-right">Forgot Password?</a>
          </div>
          <!--<div class="or-seperator"></div>
          <p class="text-center">Connect with our social media </p>
          <div class="text-center social-btn">
            <a href="https://www.facebook.com/AmericanLegalNet/" target="_blank" class="btn btn-primary"><i class="fa fa-facebook"></i>&nbsp; Facebook</a>
            <a href="https://www.youtube.com/channel/UClKq3tkByH1adbnoGIMet-A/videos" target="_blank" class="btn btn-danger"><i class="fa fa-youtube"></i>&nbsp; Youtub</a>
            <a href="https://twitter.com/aln3" target="_blank" class="btn btn-info"><i class="fa fa-twitter"></i>&nbsp; Twitter</a>
          </div>-->
          <!--<p style="padding-top:10px;" class="text-center text-muted small">Don't have an account? <a href="#">Sign up here!</a></p>-->
          <p style="padding-top:10px;" class="text-center text-muted small">&nbsp;&nbsp;<a href="#"></a></p>
        </form>
        <!--<p style="padding-top:10px;" class="text-center text-muted small">Don't have an account? <a href="#">Sign up here!</a></p>-->
      </div>
      <div *ngIf="msg" role="alert" class="success alert alert-info alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span class="sr-only">Error:</span>
        {{msg}}
      </div>
    </div>


    <section id="products" class="visible-lg-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="section-title">Our Products</div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <marquee behavior="scroll" direction="left">
              <ul class="products">
                <li><img src="../../assets/images/docket.jpg"></li>
                <li><img src="../../assets/images/edockets.jpg"></li>
                <li><img src="../../assets/images/formsworkflow.jpg"></li>
                <li><img src="../../assets/images/smartdockets.jpg"></li>
                <li><img src="../../assets/images/efiling.jpg"></li>
              </ul>
            </marquee>
          </div>
        </div>
        <div class="row" style="background-color:lightsteelblue;padding-top:10px">
          <div class="col-md-12">
            <div class="footer_logo"><img src="../../assets/images/alncorp_logo.png" class="img-responsive"></div>
            <div class="copyright">Copyright © 2019 ProcessingTool. All Rights Reserved. </div>
          </div>
        </div>
      </div>
    </section>
    <notifier-container></notifier-container>
    <bs-modal #modal data-backdrop="static" data-keyboard="false">
      <bs-modal-header [showDismiss]="true" class="alert-success">
        <h4 class="modal-title">Forgot Password</h4>
      </bs-modal-header>
      <bs-modal-body>
        <div class="row">
          <div class="col-md-12">
            <form class="well form-horizontal" [formGroup]="Forgotpwd">
              <!-- Form Name -->
              <div class="form-group">
                <label class="col-md-4 control-label">User Name</label>
                <div class="col-md-8 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>
                    <input name="first_name" autocomplete="off" placeholder="User Name" formControlName="username" class="form-control" type="text">
                  </div>
                </div>
              </div>
              <div class="alert alert-success" role="alert" id="success_Mailmessage">{{EmailMsg}}<i class="glyphicon glyphicon-thumbs-up"></i></div>
              <div class="form-group">
                <label class="col-md-4 control-label">Temporary Password</label>
                <div class="col-md-8 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></span>
                    <input name="last_name" autocomplete="off" placeholder="Temporary Password" formControlName="oldPwd" class="form-control" type="password">
                  </div>
                </div>
              </div>
              <div class="form-group" id="pswd">
                <label class="col-md-4 control-label">Password</label>
                <div class="col-md-8 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></span>
                    <input name="user_password" autocomplete="off" placeholder="Password" formControlName="newPwd" class="form-control" type="password">
                  </div>
                </div>
              </div>
              <div class="form-group" id="confirmpswd">
                <label class="col-md-4 control-label">Confirm Password</label>
                <div class="col-md-8 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></span>
                    <input name="confirm_password" autocomplete="off" placeholder="Confirm Password"  formControlName="confirmPwd" class="form-control" type="password">
                  </div>
                </div>
              </div>
              <div class="alert alert-success" role="alert" id="success_changepwdmessage">{{changepwdmsg}}<i class="glyphicon glyphicon-thumbs-up"></i></div>
            </form>
          </div>
        </div>
      </bs-modal-body>
      <bs-modal-footer [showDefaultButtons]="false">
        <div style="text-align:center">
          <a class="btn btn-default" (click)="modal.dismiss()">Cancel</a>
        </div>
      </bs-modal-footer>
    </bs-modal>
  </div>
</body>
