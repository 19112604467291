<link href="../../assets/css/metisMenu.min.css" rel="stylesheet" />
<link href="../../assets/css/sb-admin-2.min.css" rel="stylesheet" />
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8" />
  <title>Searches</title>
  <!--<link href="//netdna.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css" rel="stylesheet" />-->
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
</head>
<body>
  <div id="page-wrapper">
    <div class="row">
      <div class="col-lg-12 well">
        <h2 class="page-header" style="color:blue; border:none">Daily Reconciliation</h2>
      </div>
    </div>


    <div class="row">

      <div class="col-lg-8 well">

        <h2 style="float:left;padding-right:140px;color:blue;" (click)="curYearChange()">{{curYear}}</h2>

        <ul style="display:inline-block;text-align:center;margin-bottom:4px">
          <li *ngFor="let mon of months" style="float:left;display:inline-block;margin:35px 15px 0px 0px;color:blue;text-align:center;">
            <b> {{mon.name}}</b>
          </li>

        </ul>
        <hr style="border-top: 1px solid #0000ff;height: 5px;padding: 0px; color:blue; margin-bottom:2px;margin-top:0px" />
        <h5 style="float:left;padding-right:61px;font-weight:300"><b>Los Angeles Superior Court</b></h5>
        <ul style="display:inline;text-align:center;">
          <li *ngFor="let mon of reportData" style="float:left;display:inline-block;margin:1px 14px 0px 0px;color:blue;text-align:center;">
            <!--<div *ngIf="mon.color==='red'">
    <button style="width:30px;height:30px;border: none;" class="btn-danger"></button>
  </div>
  <div *ngIf="mon.color==='green'">
    <button style="width:30px;height:30px;border: none;" class="btn-success"></button>
  </div>
  <div *ngIf="mon.color==='black'">
    <button style="width:30px;height:30px;border: none;" class="btn-warning"></button>
  </div>-->
            <button style="width:30px;height:30px;border: none;" class="btn-danger" *ngIf="mon.status==='red'"></button>
            <button style="width:30px;height:30px;border: none;" class="btn-success" *ngIf="mon.status==='green'"></button>
            <button style="width:30px;height:30px;border: none;" class="btn-dark" *ngIf="mon.status==='deactive'"></button>
          </li>

        </ul>
        <!--<hr style="border: 2px solid blue; float:right;  border-radius: 5px;">-->
        <!--style="background-color:{{color}}; width:30px;height:30px;border: none;"-->
      </div>

    </div>
    <div class="row">
      <div style="width:66.66666667%;">
        <ul style="display:inline-block;text-align:center;margin-bottom:4px;padding-left:0px;margin:0px">
          <li style="float:left;display:inline-block;">
            <a (click)="prevYearChange()"><h4 style="float:left;display:inline-block;padding-right:140px;color:blue;">{{prevYear}}</h4></a>
          </li>

        </ul>
        <hr style="border-top: 1px solid #0000ff;height: 0px;padding: 0px; color:blue; margin-bottom:2px;margin-top:0px" />


      </div>
    </div>
  </div>


</body>
</html>
