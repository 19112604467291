import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GenericActionResult } from '../model/generic-action-result.model';
import { WebApiService } from '../Services/web-api.service';
import { SearchDTO } from '../model/Search.model';


//const BASE_URL_WEBAPI = env.webApiBaseUri;
const BASE_URL_WEBAPI = 'https://localhost:44371/api';

const efilingServiceRoutes = {
  EFilingSearchData: '/Search/GetEFilingSearchData',
  GetEFilingDt: (eFilingID: number) => `/Search/GetEFilingData/${eFilingID}`,
  GetEFilingPartiesDt: (eFilingID: number) => `/Search/GetEFilingPartiesData/${eFilingID}`,
  GetEFilingDocDt: (eFilingID: number) => `/Search/GetEFilingDocumentData/${eFilingID}`,
  GetEFilingPaymentDt: (eFilingID: number) => `/Search/GetEFilingPaymentData/${eFilingID}`,
  GetEFilingCourtResponseDt: (eFilingID: number) => `/Search/GetEFilingCourtResponseData/${eFilingID}`,
  GetEFilingFeeDt: (eFilingID: number) => `/Search/GetEFilingFeeData/${eFilingID}`,
  GetEFilingTrnsndPymtDt: (eFilingID: number) => `/Search/GetEFilingTransactionAndPaymentData/${eFilingID}`,
  GetEFilingOCDt: (eFilingID: number) => `/Search/GetEFilingOCData/${eFilingID}`,
  GetEFilingPartiesOCDt: (eFilingID: number) => `/Search/GetEFilingPartiesOCData/${eFilingID}`,
  GetEFilingDocOCDt: (eFilingID: number) => `/Search/GetEFilingDocumentOCData/${eFilingID}`,
  GetEFilingPaymentOCDt: (eFilingID: number) => `/Search/GetEFilingPaymentOCData/${eFilingID}`,
  GetEFilingCourtResponseOCDt: (eFilingID: number) => `/Search/GetEFilingCourtResponseOCData/${eFilingID}`,
  GetEFilingFeeOCDt: (eFilingID: number) => `/Search/GetEFilingFeeOCData/${eFilingID}`,
  GetEFilingTrnsndPymtOCDt: (eFilingID: number) => `/Search/GetEFilingTransactionAndPaymentOCData/${eFilingID}`,
  GetEFilingStates: `/Search/GetCourtStates`,
  GetEFilingCourts: `/Search/GetCourts`,
  GetEFilingStatus: `/Search/GetStatus`,
  InstJtiEmailData: `/Accounts/postData`,
  GetReportData: (year: number) =>`/Accounts/getReportData/?year=${year}`,





};

@Injectable()
export class EfilingService {

  constructor(private webApiService: WebApiService) { }

  GetEFilingSearchData(SearchEntity: SearchDTO): Observable<GenericActionResult> {

    let body = {
      ConfirmationNumber: SearchEntity.confirmationNumber,
      OrderNumber: SearchEntity.orderNumber,
      CaseTitle: SearchEntity.caseTitle,
      StatusCode: SearchEntity.statusCode,
      StepType: SearchEntity.stepType,
      FilerFullName: SearchEntity.filerFullName,
      FilerEmailID: SearchEntity.filerEmailID,
      MatterReferenceCode: SearchEntity.matterReferenceCode,
      ClientReferenceCode: SearchEntity.clientReferenceCode,
      CreatedFromDate: SearchEntity.createdFromDate,
      CreatedToDate: SearchEntity.createdToDate,
      SubmittedFromDate: SearchEntity.submittedFromDate,
      SubmittedToDate: SearchEntity.submittedToDate,
      CaseNumber: SearchEntity.caseNumber,
      courtsCentral: SearchEntity.courtsCentral,
      courtProcFromDate: SearchEntity.courtProcFromDate,
      courtProcToDate: SearchEntity.courtProcToDate,
      statusCodeName: SearchEntity.statusCodeName,
      email: SearchEntity.email,
    }

    return this.webApiService.post(efilingServiceRoutes.EFilingSearchData, body);
  }

  GetEFilingData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingDt(eFilingID));
  }

  GetEFilingPartiesData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingPartiesDt(eFilingID));
  }
  GetEFilingDocData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingDocDt(eFilingID));
  }
  GetEFilingPaymentData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingPaymentDt(eFilingID));
  }
  GetEFilingCourtResponseData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingCourtResponseDt(eFilingID));
  }
  GetEFilingFeeData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingFeeDt(eFilingID));
  }
  GetEFilingTransactionandPaymentData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingTrnsndPymtOCDt(eFilingID));
  }
  GetEFilingOCData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingOCDt(eFilingID));
  }

  GetEFilingPartiesOCData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingPartiesOCDt(eFilingID));
  }
  GetEFilingDocOCData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingDocOCDt(eFilingID));
  }
  GetEFilingPaymentOCData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingPaymentOCDt(eFilingID));
  }
  GetEFilingCourtResponseOCData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingCourtResponseOCDt(eFilingID));
  }
  GetEFilingFeeOCData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingFeeOCDt(eFilingID));
  }
  GetEFilingTransactionandPaymentOCData(eFilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingTrnsndPymtOCDt(eFilingID));
  }
  getCourtStates(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingStates);
  }
  getCourts(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingCourts);
  }
  getStatus(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetEFilingStatus);
  }
  GetReportData(year: number): Observable<any> {
    return this.webApiService.get(efilingServiceRoutes.GetReportData(year));
  }
}

