import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { User } from '../model/User';
import { AuthenticationService } from '../services/authentication.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalComponent } from 'ng2-bs3-modal';
import { environment } from '../../environments/environment';
import { OldPwdValidators } from './old-pwd.validators'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('customNotification') customNotificationTmpl;
  @ViewChild('modal') Addusermodal: BsModalComponent;
  loginForm: FormGroup;
  Forgotpwd: FormGroup;
  submitted: boolean = false;
  invalidLogin: boolean = false;
  captchaError: boolean = false;
  msg: string;
  EmailMsg: string;
  changepwdmsg: string;
  loading = false;
  returnUrl: string;
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private router: Router,
    private userService: AuthenticationService, private route: ActivatedRoute,) { }
  env = environment.name;
  onSubmit() {
    //debugger;
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.msg = "Please enter username and password";
      return;
    }
    let login = new User();
    login.userName = this.loginForm.controls.userName.value;
    login.passwordHash = this.loginForm.controls.passwordHash.value;
    this.spinner.show();
    this.userService.login(login.userName, login.passwordHash)
      //.pipe(first())
        .subscribe(
      data => {
        //debugger;
        if (data != "")
          this.router.navigate([this.returnUrl]);
        else {
          this.msg = "Failed..";
          this.spinner.hide();
        }
          },
      error => {
        this.msg = error;
        this.spinner.hide();
      });
  }
  AddNewuser() {
    this.Addusermodal.open();
  }
  ngOnInit() {
    this.Forgotpwd = this.formBuilder.group({
      username: ['', Validators.required],
      oldPwd: ['', Validators.required],
      newPwd: ['', Validators.required],
      confirmPwd: ['', Validators.required]
    }, {
        validator: OldPwdValidators.matchPwds
      });
    $('#success_Mailmessage').hide();
    $('#success_changepwdmessage').hide();
    this.userService.hide();
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      passwordHash: ['', Validators.required]
    });
    this.userService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'home';
  }

}

