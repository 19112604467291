<link href="../../assets/css/metisMenu.min.css" rel="stylesheet" />
<link href="../../assets/css/sb-admin-2.min.css" rel="stylesheet" />
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8" />
  <title>Searches</title>
  <!--<link href="//netdna.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css" rel="stylesheet" />-->
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
</head>
<body>
  <div id="page-wrapper">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="page-header">eFiling</h1>
      </div>
    </div>

    <div class="col-md-12 well">
      <form novalidate [formGroup]="Frmg" class="form-horizontal">
        <div class="row">
          <div class="form-group">
            <label class="control-label col-sm-2">State</label>
            <div class="col-sm-3">
              <select class="form-control" formControlName="courtState">
                <option *ngFor="let item of stateList" [selected]="item.code == 'CA'" value={{item.code}}>
                  {{item.name}}
                </option>
              </select>
            </div>
            <label class="control-label col-sm-2">ALN Order Number:</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="ALN_ORDER_Number" formControlName="orderNumber" value="0">
            </div>

          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <label class="control-label col-sm-2">Court</label>
            <div class="col-sm-3">
              <mat-select class="form-control" formControlName="courtsCentral">
                <mat-option *ngFor="let item of courtList" value={{item.id}}>
                  {{item.name}}
                </mat-option>

              </mat-select>
            </div>
            <label class="control-label col-sm-2">Client Filing Code:</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="Client_Filling_Code" formControlName="clientReferenceCode">
            </div>
            <!--<label class="control-label col-sm-2">Step Type:</label>
            <div class="col-sm-3">
              <select class="form-control" formControlName="stepType">
                <option value="0">--Select--</option>

              </select>
            </div>-->
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <label class="control-label col-sm-2">Court Transaction ID:</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="Court_Transaction_Number" formControlName="confirmationNumber">
            </div>
            <label class="control-label col-sm-2">Created Date:</label>
            <div class="col-sm-2">
              <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="From Date" style="border-left:0px;"
                       (dateInput)="CreatedDate('input', $event)" (dateChange)="CreatedDate('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-sm-2">
              <mat-form-field>
                <input matInput [matDatepicker]="picker2" placeholder="To Date" style="border-left:0px;"
                       (dateInput)="CreatedToDate('input', $event)" (dateChange)="CreatedToDate('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <!--<label class="control-label col-sm-2">Status Type:</label>
            <div class="col-sm-3">
              <select class="form-control" formControlName="statusCode">
                <option value="0">--Select--</option>
                <option value="Draft">Draft</option>
                <option value="Submitted">Submitted</option>
                <option value="Accepted">Accepted</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>-->
            <label class="control-label col-sm-2">Status Type:</label>
            <div class="col-sm-3">
              <mat-list-item class="primary-imenu-item" role="listitem">
                <mat-select #select class="form-control" multiple formControlName="statusCode" >
                  <mat-option *ngFor="let list of statusList" [value]="list.id">
                    {{list.code}}
                  </mat-option>
                </mat-select>
              </mat-list-item>

              <!--<select class="form-control" formControlName="statusCode" multiple>
                <option *ngFor="let item of statusList" value={{item.id}}>
                  {{item.code}}
                </option>
              </select>-->
             
            </div>
            <label class="control-label col-sm-2">Submitted Date:</label>
            <div class="col-sm-2">
              <mat-form-field>
                <input matInput [matDatepicker]="picker3" placeholder="From Date" style="border-left:0px;"
                       (dateInput)="selectedDate('input', $event)" (dateChange)="selectedDate('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-sm-2">
              <mat-form-field>
                <input matInput [matDatepicker]="picker4" placeholder="To Date" style="border-left:0px;"
                       (dateInput)="selectedToDate('input', $event)" (dateChange)="selectedToDate('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <label class="control-label col-sm-2">Case Name:</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="Case_Name" formControlName="caseTitle">
            </div>

            <label class="control-label col-sm-2">Court Process Date:</label>
            <div class="col-sm-2">
              <mat-form-field>
                <input matInput [matDatepicker]="picker5" placeholder="From Date" style="border-left:0px;"
                       (dateInput)="courtProcFromDate('input', $event)" (dateChange)="courtProcFromDate('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                <mat-datepicker #picker5></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-sm-2">
              <mat-form-field>
                <input matInput [matDatepicker]="picker6" placeholder="To Date" style="border-left:0px;"
                       (dateInput)="courtProcToDate('input', $event)" (dateChange)="courtProcToDate('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                <mat-datepicker #picker6></mat-datepicker>
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="row" style="margin-top:-15px;">
          <div class="form-group">
            <div class="col">
              <label class="control-label col-sm-2">Filer's Full Name:</label>
              <div class="col-sm-3">
                <input type="text" class="form-control" id="Filler_Full_Name" formControlName="filerFullName">
              </div>
              <label class="control-label col-sm-2">Matter Number:</label>
              <div class="col-sm-3">
                <input type="text" class="form-control" id="Matter_Number" formControlName="matterReferenceCode">
              </div>
            </div>


          </div>
        </div>
        <div class="row" style="margin-top:-15px;">
          <div class="form-group">
            <label class="control-label col-sm-2">Filer's Email:</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="Filler_Email" formControlName="filerEmailID">
            </div>

          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <!--<label class="control-label col-sm-2">Client Filing Code:</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="Client_Filling_Code" formControlName="clientReferenceCode">
            </div>-->
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-11" style="text-align:center;">
            <input type="submit" value="Search" class="btn btn-primary" (click)="searchCase()" />

            <input type="submit" value="Reset" class="btn btn-primary" (click)="OnClear();" />
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12 well">
      <div class="panel-body">
        <div class='table-responsive'>
          <table id="ManageFormTable" class="table table-striped table-bordered table-sm row-border hover table-responsive">
            <!--datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">-->
            <thead>
              <tr>
                <th style="width:50px;">Open</th>
                <th style="width:100px;">Court Transaction Number</th>
                <th style="width:100px;">Status Type</th>
                <th style="width:100px;">Case Name</th>
                <th style="width:100px;">Case Number</th>
                <th style="width:100px;">User Full Name</th>
                <th style="width:100px;">User Mail ID</th>
                <th style="width:100px;">Matter Number</th>
                <th style="width:100px;">Client Filing Code</th>
                <th style="width:100px;">ALN Order Number</th>
                <!--<th style="width:100px;">Step Type</th>-->
                <th style="width:100px;">Created Date</th>
                <th style="width:100px;">Submitted Date</th>
                <th style="width:100px;">Court Process Date</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <!--<td>
                  <button class="glyphicon glyphicon-folder-open"
                          data-toggle="modal"
                          data-target="#modal" (click)="CallModal(pForm.orderNumber,1040)"></button>
                </td>-->
              </tr>
              <tr *ngFor="let pForm of pagedItems;">
                <td>
                  <button class="glyphicon glyphicon-folder-open"
                          data-toggle="modal"
                          data-target="#modal"
                          (click)="CallModal(pForm.orderNumber,pForm.confirmationNumber,courtsCentral)"></button>
                </td>
                <td>{{pForm.confirmationNumber}}</td>
                <td>{{pForm.statusCode}}</td>
                <td>{{pForm.caseTitle}}</td>
                <td>{{pForm.caseNumber}}</td>
                <td>{{pForm.filerFullName}}</td>
                <td>{{pForm.filerEmailID}}</td>
                <td>{{pForm.matterReferenceCode}}</td>
                <td>{{pForm.clientReferenceCode}}</td>
                <td>{{pForm.orderNumber}}</td>
                <td>{{pForm.dateTimeFiled |date:'MM/d/yyyy, h:mm a'}}</td>
                <td>{{pForm.dateTimeReceived |date:'MM/d/yyyy, h:mm a'}}</td>
                <td>{{pForm.courtProcessDate |date:'MM/d/yyyy, h:mm a'}}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="container">
        <div class="text-right">
          <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
            <li [ngClass]="{disabled:pager.currentPage === 1}">
              <a (click)="setPage(1)">First</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === 1}">
              <a (click)="setPage(pager.currentPage - 1)">Previous</a>
            </li>
            <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
              <a (click)="setPage(page)">{{page}}</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a (click)="setPage(pager.currentPage + 1)">Next</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a (click)="setPage(pager.totalPages)">Last</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" style="overflow-y: scroll; width:1800px; max-height:85%;  margin-top: 50px; margin-bottom:50px;">
      <div class="modal-content">
        <div class="modal-body read-only">
          <div class="panel panel-info panel-top-margin">
            <ng-container>
              <div class="panel-heading">
                <h3 class="panel-title" style="text-align: center;">
                  <span class="glyphicon glyphicon-folder-close"></span>
                  <strong> EFiling </strong> <label id="EFiling_id">
                    ( {{name}} )
                  </label>
                </h3>
              </div>
            </ng-container>

          </div>
          <div class="row">
            <div class="col-md-12">
              <form novalidate [formGroup]="pFrmg">
                <div class="form-group row">
                  <label for="txteFilingID" class="control-label col-md-2">eFilingID</label>
                  <div class="col-md-3">
                    <input type="text" id="eFilingID" [attr.readonly]="isReadOnly" class="form-control" formControlName="orderNumber" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtUserInformation" class="control-label col-md-2">User Information</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" formControlName="user" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtStatusInformation" class="control-label col-md-2">Status Information</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="statusInfo" formControlName="statusCode" />
                  </div>
                </div>

                <div class="form-group row">
                  <label for="txtCourtDivision" class="control-label col-md-2">Court Division</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="courtDivision" formControlName="courtDivision" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtSubDivision" class="control-label col-md-2">Sub Division</label>
                  <div class="col-md-9">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="statusDivision" formControlName="subDivision" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtLocation" class="control-label col-md-2">Location</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="location" formControlName="locationName" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtclmlInfo" class="control-label col-md-2">Client/Matter Information</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="clmlInfo" formControlName="clientMatterReferenceCode" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtecaseName" class="control-label col-md-2">Case Name</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="ecaseName" formControlName="caseTitle" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtecaseNumber" class="control-label col-md-2">Case Number</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="ecaseNumber" formControlName="caseNumber" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtStatusInformation" class="control-label col-md-2">Is Sealed</label>
                  <div class="col-md-1">
                    <label class="checkbox-inline">
                      <input type="radio" [attr.disabled]="false" [value]="false" id="isSealedNo" formControlName="conditionallySealed" [checked]="conditionallySealed === false" /> No
                    </label>
                  </div>
                  <div class="col-xs-0">
                    <label class="checkbox-inline">
                      <input type="radio" [attr.disabled]="false" [value]="true" id="isSealedNo" formControlName="conditionallySealed" [checked]="conditionallySealed === true" /> Yes
                    </label>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtsplInstruction" class="control-label col-md-2">Special Instruction</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" id="splInstruction" class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtconfNumber" class="control-label col-md-2">Confirmation Number</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" formControlName="confirmationNumber" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtfiled" class="control-label col-md-2">Filed</label>
                  <div class="col-md-3">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="Filed" />
                  </div>
                </div>

                <div class="form-group row">
                  <label for="txtintlReason" class="control-label col-md-2">Inital Reason</label>
                  <div class="col-md-9">
                    <input type="text" [attr.readonly]="isReadOnly" class="form-control" id="instReason" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtIsCmpLitigation" class="control-label col-md-2">Is Complex Litigation</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtmessage" class="control-label col-md-2">Submission Message</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="smessage" />
                  </div>
                </div>

                <div class="form-group row">
                  <label for="txteFilingSInfo" class="control-label col-md-2">eFiling Step Information</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="eFilingSInfo" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txteFilingSMod" class="control-label col-md-2">eFiling Step Modified</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="eFilingSMod" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtreqEnvelope" class="control-label col-md-2">Request Data</label>
                  <a class="glyphicon glyphicon-folder-open" (click)="displayXML('Request')"></a>
                  <div class="col-md-9">
                    <input type="text" class="form-control " [attr.readonly]="isReadOnly" id="reqEnvelope" formControlName="requestData" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtreplyEnvelop" class="control-label col-md-2">Response Data</label>
                  <a class="glyphicon glyphicon-folder-open" (click)="displayXML('Response')"></a>
                  <div class="col-md-9">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="replyEnvelope" formControlName="responseData" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="txtStatusInformation" class="control-label col-md-2">Is New Filing</label>
                  <div class="col-md-1">
                    <label class="checkbox-inline">
                      <input type="radio" [attr.disabled]="false" [value]="false" name="thisIsANewCase" id="IsANewCaseNo" formControlName="thisIsANewCase" [checked]="thisIsANewCase === false" /> No
                    </label>
                  </div>
                  <div class="col-xs-0">
                    <label class="checkbox-inline">
                      <input type="radio" [attr.disabled]="false" [value]="true" name="thisIsANewCase" id="IsANewCaseYes" formControlName="thisIsANewCase" [checked]="thisIsANewCase === true" /> Yes
                    </label>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="lblJurisdictionAmt" class="control-label col-md-2">Jurisdictional Amount</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="jurisdicationalAmt" formControlName="juriAmount" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="lblfeenotExceed" class="control-label col-md-2">Fees do not exceed</label>
                  <div class="col-md-3">
                    <!--<input type="text" class="form-control" [attr.readonly]="isReadOnly" id="feenotExceed" formControlName="feenotexceed"/>-->
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="feedonotexceed" formControlName="feesdonotExceed" />

                  </div>
                </div>
                <div class="form-group row">
                  <label for="lblappVerNo" class="control-label col-md-2">Created</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="CreatedDt" formControlName="dateTimeFiled" />
                  </div>
                  <label for="lblbrowser" class="control-label col-md-2" style="width:73px;">Modified</label>
                  <div class="col-md-2">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="ModifiedDt" formControlName="dateTimeReceived" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="lblappVerNo" class="control-label col-md-2">Application Version Number</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="appVerNo" />
                  </div>
                  <label for="lblbrowser" class="control-label col-md-2" style="width:73px;">Browser</label>
                  <div class="col-md-2">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" id="ebrowser" />
                  </div>
                  <label for="lblipadd" class="control-label col-md-2" style="width:110px;">Ip Address</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" [attr.readonly]="isReadOnly" style="width:261px;" id="eipaddress" />
                  </div>
                </div>

              </form>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-info panel-top-margin">
                <div class="panel-heading">
                  <h3 class="panel-title" style="text-align:center"><span class="glyphicon glyphicon-user"></span><strong> Parties</strong> </h3>
                </div>
              </div>
              <div class='table-responsive'>
                <table id="ManageFormTable" class="table table-striped table-bordered table-sm row-border hover table-responsive">
                  <!--datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">-->
                  <thead>
                    <tr>
                      <th style="width:50px;">Open</th>
                      <th style="width:100px;">ID</th>
                      <th style="width:100px;">Person</th>
                      <th style="width:100px;">Organisation Name</th>
                      <th style="width:100px;">Bar Number</th>
                      <th style="width:100px;">Is Filler</th>
                      <th style="width:100px;">Party Type</th>
                      <th style="width:100px;">Role Type</th>
                      <th style="width:100px;">Created</th>
                      <th style="width:100px;">Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pEFParty of PartyList;">
                      <td>
                        <a class="glyphicon glyphicon-folder-open"></a>
                      </td>
                      <td>{{pEFParty.partyId}}</td>
                      <td>{{pEFParty.fullName}}</td>
                      <td>{{pEFParty.organization}}</td>
                      <td></td>
                      <td>{{pEFParty.isFiler=="True"?pEFParty.isFiler:" "}}</td>
                      <td>{{pEFParty.partyType}}</td>
                      <td>{{pEFParty.roleType}}</td>
                      <td>{{pEFParty.partyCreatedDate|date:'MM/d/yyyy, h:mm a'}}</td>
                      <td>{{pEFParty.partyModifiedDate|date:'MM/d/yyyy, h:mm a'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-info panel-top-margin">
                <div class="panel-heading">
                  <h3 class="panel-title" style="text-align:center"><span class="fa fa-file-pdf-o" aria-hidden="true"></span> <strong> Source Documents</strong> </h3>
                </div>
              </div>
              <div class='table-responsive'>
                <table id="ManageFormTable" class="table table-striped table-bordered table-sm row-border hover table-responsive">
                  <!--datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">-->
                  <thead>
                    <tr>
                      <th style="width:50px;">Open</th>
                      <th style="width:100px;">ID</th>
                      <th style="width:100px;">Type</th>
                      <th style="width:300px;">Description</th>
                      <th style="width:100px;">File Size</th>
                      <th style="width:100px;">Attachment Type</th>
                      <th style="width:100px;">Created</th>
                      <th style="width:100px;">Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pEFDoc of DocList;">
                      <td>
                        <a class="glyphicon glyphicon-folder-open" (click)="getFile(pEFDoc.url)"></a>
                      </td>
                      <td>{{pEFDoc.documentId}}</td>
                      <td>{{pEFDoc.type}}</td>
                      <td>{{pEFDoc.documentName}}</td>
                      <td>{{pEFDoc.fileSize}}</td>
                      <td>{{pEFDoc.attachmentType}}</td>
                      <td>{{pEFDoc.documentCreatedDate|date:'MM/d/yyyy, h:mm a'}}</td>
                      <td>{{pEFDoc.documentModifiededDate|date:'MM/d/yyyy, h:mm a'}}</td>
                      <td hidden>
                        {{pEFDoc.url}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-info panel-top-margin">
                <div class="panel-heading">
                  <h3 class="panel-title" style="text-align:center"><span style="text-align:center"></span><strong>Payment Receipts</strong> </h3>
                </div>
              </div>
              <div class='table-responsive'>
                <table id="ManageFormTable" class="table table-striped table-bordered table-sm row-border hover table-responsive">
                  <thead>
                    <tr>
                      <th style="width:50px;">Open</th>
                      <th style="width:100px;">ID</th>
                      <th style="width:100px;">Response ID</th>
                      <th style="width:500px;">Name</th>
                      <th style="width:100px;">Created</th>
                      <th style="width:100px;">Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pEFPay of PmtList;">
                      <td><a class="glyphicon glyphicon-folder-open" (click)="getFile(pEFPay.url)"></a></td>
                      <td>{{pEFPay.documentID}}</td>
                      <td>{{pEFPay.responseID}}</td>
                      <td>{{pEFPay.documentName}}</td>
                      <td>{{pEFPay.receivedDate |date:'MM/d/yyyy, h:mm a'}}</td>
                      <td>{{pEFPay.processedDate|date:'MM/d/yyyy, h:mm a'}}</td>
                      <td hidden>
                        {{pEFPay.url}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-info panel-top-margin">
                <div class="panel-heading">
                  <h3 class="panel-title" style="text-align:center"><strong>Court Response</strong></h3>
                </div>
              </div>
              <div class='table-responsive'>
                <table id="ManageFormTable" class="table table-striped table-bordered table-sm row-border hover table-responsive">
                  <thead>
                    <tr>
                      <th style="width:50px;">Open</th>
                      <th style="width:100px;">ID</th>
                      <th style="width:100px;">Is Processed</th>
                      <th style="width:400px;">Message</th>
                      <th style="width:100px;">Confirmatin Number</th>
                      <th style="width:100px;">Received Date</th>
                      <th style="width:100px;">Processed Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pEFCrtRsp of crtRspList;">
                      <td><a class="glyphicon glyphicon-folder-open" (click)="getFile(pEFCrtRsp.xmlResponse)"></a></td>
                      <td>{{pEFCrtRsp.asyResponseID}}</td>
                      <td>{{pEFCrtRsp.isProcessed}}</td>
                      <td>{{pEFCrtRsp.message}}</td>
                      <td>{{pEFCrtRsp.confirmationNumber}}</td>
                      <td>{{pEFCrtRsp.receivedDate |date:'MM/d/yyyy, h:mm a'}}</td>
                      <td>{{pEFCrtRsp.processedDate |date:'MM/d/yyyy, h:mm a'}}</td>
                      <td hidden>
                        {{pEFCrtRsp.xmlResponse}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-info panel-top-margin">
                <div class="panel-heading">
                  <h3 class="panel-title" style="text-align:center"><strong>Fees</strong></h3>
                </div>
              </div>
              <div class='table-responsive'>
                <table id="ManageFormTable" class="table table-striped table-bordered table-sm row-border hover table-responsive">
                  <!--datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">-->
                  <thead>
                    <tr>
                      <th style="width:50px;">ID</th>
                      <th style="width:400px;">Name</th>
                      <th style="width:100px;">Estimated Amount</th>
                      <th style="width:100px;">Received Amount</th>
                      <th style="width:100px;">Created</th>
                      <th style="width:100px;">Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pEFFee of FeeList;">
                      <td>{{pEFFee.feeID}}</td>
                      <td>{{pEFFee.feeName}}</td>
                      <td>{{pEFFee.estimatedAmount}}</td>
                      <td>{{pEFFee.receivedAmount}}</td>
                      <td>{{pEFFee.createdDate |date:'MM/d/yyyy, h:mm a'}}</td>
                      <td>{{pEFFee.modifiedDate |date:'MM/d/yyyy, h:mm a'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-info panel-top-margin">
                <div class="panel-heading">
                  <h3 class="panel-title" style="text-align:center"><strong>Transactions & Payments</strong></h3>
                </div>
              </div>
              <div class='table-responsive'>
                <table id="ManageFormTable" class="table table-striped table-bordered table-sm row-border hover table-responsive">
                  <!--datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">-->
                  <thead>
                    <tr>
                      <th style="width:50px;">Open</th>
                      <th style="width:100px;">Order ID</th>
                      <th style="width:100px;">MStatus</th>
                      <th style="width:150px;">Transaction Type</th>
                      <th style="width:100px;">Payment Type</th>
                      <th style="width:100px;">Fee Name</th>
                      <th style="width:100px;">amount</th>
                      <th style="width:100px;">Card Holder Name</th>
                      <th style="width:100px;">Date Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pTransndPay of TransndPay;">
                      <td><a class="glyphicon glyphicon-folder-open"></a></td>
                      <td>{{pTransndPay.orderID}}</td>
                      <td>{{pTransndPay.mStatus}}</td>
                      <td>{{pTransndPay.trasnsactionType}}</td>
                      <td>{{pTransndPay.paymenttype}}</td>
                      <td>{{pTransndPay.feeName}}</td>
                      <td>{{pTransndPay.feeAmountReceived}}</td>
                      <td>{{pTransndPay.cardHolderName}}</td>
                      <td>{{pTransndPay.dateCreated|date:'MM/d/yyyy, h:mm a'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-info panel-top-margin">
                <div class="panel-heading">
                  <h3 class="panel-title" style="text-align:center"><strong>Exceptions</strong></h3>
                </div>
              </div>
              <div class='table-responsive'>
                <table id="ManageFormTable" class="table table-striped table-bordered table-sm row-border hover table-responsive">
                  <!--datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">-->
                  <thead>
                    <tr>
                      <th style="width:50px;">ID</th>
                      <th style="width:700px;">Message</th>
                      <th style="width:100px;">Created</th>
                      <th style="width:100px;">Modified</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 submit-buttons" style="text-align:center">
              <button type="button" class="btn btn-primary" (click)="close()">Close</button><span style="padding-left:15px"></span><span style="font-weight:bold;color:darkred;" *ngIf="message">
                {{message}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</body>
</html>
<style>
  .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
    background: #00ff21;
  }
</style>
