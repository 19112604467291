import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../services/authentication.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EfilingService } from '../services/efiling.service';
import { SearchDTO, CourtsDTO, StatusDTO } from '../model/Search.model';
import { EFilingDetailsDTO } from '../model/Search.model';
import { CourtStatesDTO } from '../model/Search.model';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { PagerService } from '../services/pager.service';
import { saveAs } from 'file-saver';
import { style } from '@angular/animations';
import { debug } from 'util';
import { MatSelectChange, MatOption } from '@angular/material';
declare var $: any;

@Component({
  selector: 'app-Searches',
  templateUrl: './Searches.component.html',
})
export class SearchesComponent implements OnInit {

  private fileList: string[] = new Array<string>();
  private fileList$: Subject<string[]> = new Subject<string[]>();

  message: string;
  public isReadOnly: boolean = false;
  Frmg: FormGroup;
  pFrmg: FormGroup;

  SearchEntity: SearchDTO = new SearchDTO();
  EFilingEntity: EFilingDetailsDTO = new EFilingDetailsDTO();

  arrayList: any[] = [];
  PartyList: any[] = [];
  DocList: any[] = [];
  PmtList: any[] = [];
  crtRspList: any[] = [];
  FeeList: any[] = [];
  TransndPay: any[] = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];

  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  selectStatusText: string;
  conditionallySealed: boolean;
  thisIsANewCase: boolean;
  stateList: CourtStatesDTO;
  courtList: CourtsDTO;
  statusList: StatusDTO[]=[];


  constructor(
    private spinner: NgxSpinnerService,
    private Nav: AuthenticationService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private efilingService: EfilingService,
    private pagerService: PagerService,
  ) { }

  ngOnInit() {
    this.Nav.show();
    this.spinner.show();
    this.buildSearchForm();
    this.buildForm();
    this.getStates();
    this.getCourts();
    this.getStatus();


    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }

  public name: any;
  public sPath: any;
  public sType: any;

  buildSearchForm() {
    var date = new FormControl(new Date());
    this.Frmg = this.fb.group({
      confirmationNumber: [''],
      orderNumber: [''],
      caseTitle: [''],
      statusCode: [''],
      stepType: [''],
      filerFullName: [''],
      filerEmailID: [''],
      matterReferenceCode: [''],
      clientReferenceCode: [''],
      caseNumber: [''],
      courtState: [''],
      courtsCentral: [''],

      citationDate: [new FormControl(new Date())]
    });
  }

  buildForm() {
    this.pFrmg = this.fb.group({
      alnUserId: [''],
      amountInControversy: [''],
      attorneyCaseParticipantID: [''],
      callbackEmailSent: [''],
      caseCategoryCode: [''],
      caseIsConfidential: [''],
      caseNumber: [''],
      caseTitle: [''],
      caseTrackingID: [''],
      caseTypeCode: [''],
      citationDate: [''],
      citationIdentification: [''],
      clientMatterReferenceCode: [''],
      clientReference: [''],
      clientReferenceCode: [''],
      conciergeCustomerCode: [''],
      conditionallySealed: [''],
      confirmationNumber: [''],
      courtDivision: [''],
      courtFirmGuid: [''],
      courtUserGuid: [''],
      createdFromDate: [''],
      createdToDate: [''],
      customerCode: [''],
      dateTimeDeleted: [''],
      dateTimeFiled: [''],
      dateTimeReceived: [''],
      deliverCourtesyCopy: [''],
      feePaid: [''],
      feesdonotExceed: [''],
      feeWaiverAppliesToThisOrder: [''],
      filingAttorneyCode: [''],
      filingAttorneyEmail: [''],
      filingAttorneyHasChanged: [''],
      filingAttorneyVerb: [''],
      firstComplaintID: [''],
      governmentAgencyFeeWaiver: [''],
      juriAmount: [''],
      jurisAmountCode: [''],
      locationCode: [''],
      matterReferenceCode: [''],
      messageToClerk: [''],
      noFeeCase: [''],
      noFeeSection: [''],
      numberOfParcels: [''],
      orderNumber: [''],
      ownedBy: [''],
      parentLocationName: [''],
      statusCode: [''],
      subDivision: [''],
      submittedDocumentsCanBeDeleted: [''],
      submittedFromDate: [''],
      submittedToDate: [''],
      thisIsANewCase: [''],
      user: [''],
      locationName: [''],
      requestData: [''],
      responseData: [''],
      //isFiler: [''],
      //partyCreatedDate: [''],
      //partyModifiedDate: ['']


    })
  }

  CreatedDate(input: any, Event: any) {
    this.SearchEntity.createdFromDate = Event.targetElement.value;
  }

  CreatedToDate(input: any, Event: any) {
    this.SearchEntity.createdToDate = Event.targetElement.value;
  }

  selectedDate(input: any, Event: any) {
    this.SearchEntity.submittedFromDate = Event.targetElement.value;
  }

  selectedToDate(input: any, Event: any) {
    this.SearchEntity.submittedToDate = Event.targetElement.value;
  }

  courtProcFromDate(input: any, Event: any) {
    this.SearchEntity.courtProcFromDate = Event.targetElement.value;
  }

  courtProcToDate(input: any, Event: any) {
    this.SearchEntity.courtProcToDate = Event.targetElement.value;
  }

  //getSelectedOptionText(event: MatSelectChange) {
  //  debugger;
  //  let selectedData = {
  //    code: event.value[0],
  //    value: event.source.value
  //  };
  //  console.log(selectedData);
  //}

  searchCase(): void {
    this.spinner.show();
    this.SearchEntity.confirmationNumber = this.Frmg.controls.confirmationNumber.value;
    this.SearchEntity.orderNumber = this.Frmg.controls.orderNumber.value;
    this.SearchEntity.caseTitle = this.Frmg.controls.caseTitle.value;
    this.SearchEntity.statusCode = this.Frmg.controls.statusCode.value;
    this.SearchEntity.stepType = this.Frmg.controls.stepType.value;
    this.SearchEntity.filerFullName = this.Frmg.controls.filerFullName.value;
    this.SearchEntity.filerEmailID = this.Frmg.controls.filerEmailID.value;
    this.SearchEntity.matterReferenceCode = this.Frmg.controls.matterReferenceCode.value;
    this.SearchEntity.clientReferenceCode = this.Frmg.controls.clientReferenceCode.value;
    this.SearchEntity.courtsCentral = this.Frmg.controls.courtsCentral.value;
    this.SearchEntity.statusCodeName =this.getStatusCode(this.SearchEntity.statusCode);

    this.SearchEntity.email = localStorage.getItem('LoginEmail');

    //this.SearchEntity.createdFromDate = this.Frmg.controls.createdFromDate.value;
    //this.SearchEntity.createdToDate = this.Frmg.controls.createdToDate.value;;
    //this.SearchEntity.submittedFromDate = null;
    //this.SearchEntity.submittedToDate = null;
    this.SearchEntity.caseNumber = "";
    this.efilingService.GetEFilingSearchData(this.SearchEntity)
      .subscribe(result => {
        if (result.is_success) {
          this.arrayList = result.content as [];
          this.spinner.hide();
          if (result.content.length > 0) {
            this.setPage(1);
          }
        }
        else { this.spinner.hide(); }
      }, error => console.error(error));
  }
  getStatusCode(statusIDs: any) {
    var res = [];
    for (var i = 0; i <= statusIDs.length - 1; i++) {
      console.log(this.statusList.filter(a => a.id == statusIDs[i])[0].code);
      res.push(this.statusList.filter(a => a.id == statusIDs[i])[0].code);
    }
    return res;
  }

  setPage(page: number) {
    this.spinner.show();
    this.pager = this.pagerService.getPager(this.arrayList.length, page);

    this.pagedItems = this.arrayList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.spinner.hide();

  }
  CallModal(orderNumber: number, confirmationNumber: string) {
    var courtId = this.SearchEntity.courtsCentral;
    debugger;
    this.name = confirmationNumber;
    this.spinner.show();
    if (courtId == '2') {
      this.efilingService.GetEFilingData(orderNumber)
        .subscribe(result => {
          debugger;
          if (result.is_success) {
            this.EFilingEntity = result.content[0];// as EFilingDetailsDTO;
            this.EFilingEntity.user = result.content[0].user[0].filerEmailID;

            this.pFrmg.setValue(this.EFilingEntity);
            this.callPartyDetails(orderNumber);
            this.callDocDetails(orderNumber);
            this.callPaymentDetails(orderNumber);
            this.callCrtRespDetails(orderNumber);
            this.callFeeDetails(orderNumber);
            this.TransactionandPaymentDetails(orderNumber);
            setTimeout(() => {
              this.spinner.hide();
            }, 3000);
          }
        }, error => console.error(error));
    } else {
      this.efilingService.GetEFilingOCData(orderNumber)
        .subscribe(result => {
          debugger;
          if (result.is_success) {
            this.EFilingEntity = result.content[0];// as EFilingDetailsDTO;
            this.EFilingEntity.user = result.content[0].user[0].filerEmailID;
            this.pFrmg.setValue(this.EFilingEntity);
            this.callPartyOCDetails(orderNumber);
            this.callDocOCDetails(orderNumber);
            this.callPaymentOCDetails(orderNumber);
            this.callCrtRespOCDetails(orderNumber);
            this.callFeeOCDetails(orderNumber);
            this.TransactionandPaymentOCDetails(orderNumber);
            setTimeout(() => {
              this.spinner.hide();
            }, 3000);
          }
        }, error => console.error(error));
    }
  }

  callPartyDetails(orderNumber: number) {
    this.efilingService.GetEFilingPartiesData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.PartyList = result.content as [];
        }
      }, error => console.error(error));
  }

  callDocDetails(orderNumber: number) {
    this.efilingService.GetEFilingDocData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.DocList = result.content as [];
        }
      }, error => console.error(error));
  }

  callPaymentDetails(orderNumber: number) {
    this.efilingService.GetEFilingPaymentData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.PmtList = result.content as [];
        }
      }, error => console.error(error));
  }

  callCrtRespDetails(orderNumber: number) {
    this.efilingService.GetEFilingCourtResponseData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.crtRspList = result.content as [];
        }
      }, error => console.error(error));
  }

  callFeeDetails(orderNumber: number) {
    this.efilingService.GetEFilingFeeData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.FeeList = result.content as [];
        }
      }, error => console.error(error));
  }

  TransactionandPaymentDetails(orderNumber: number) {
    this.efilingService.GetEFilingTransactionandPaymentData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.TransndPay = result.content as [];
        }
      }, error => console.error(error));
  }
  callPartyOCDetails(orderNumber: number) {
    this.efilingService.GetEFilingPartiesOCData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.PartyList = result.content as [];
        }
      }, error => console.error(error));
  }

  callDocOCDetails(orderNumber: number) {
    this.efilingService.GetEFilingDocOCData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.DocList = result.content as [];
        }
      }, error => console.error(error));
  }

  callPaymentOCDetails(orderNumber: number) {
    this.efilingService.GetEFilingPaymentOCData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.PmtList = result.content as [];
        }
      }, error => console.error(error));
  }

  callCrtRespOCDetails(orderNumber: number) {
    this.efilingService.GetEFilingCourtResponseOCData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.crtRspList = result.content as [];
        }
      }, error => console.error(error));
  }

  callFeeOCDetails(orderNumber: number) {
    this.efilingService.GetEFilingFeeOCData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.FeeList = result.content as [];
        }
      }, error => console.error(error));
  }

  TransactionandPaymentOCDetails(orderNumber: number) {
    this.efilingService.GetEFilingTransactionandPaymentData(orderNumber)
      .subscribe(result => {
        if (result.is_success) {
          this.TransndPay = result.content as [];
        }
      }, error => console.error(error));
  }

  OnClear() {
    this.Frmg.reset();
    this.arrayList = [];
    this.pagedItems = [];
    this.setPage(1);
  }

  close() {
    this.message = "";
    this.buildForm();
    $('#modal').modal('hide');
  }

  getFile(sPath: string){
    if (sPath != null) {
      var a = document.createElement('a');
      a.target = '_blank';
      a.href = sPath;
      a.click();
    }
  }

  displayXML(sType: string) {
    if (sType.match("Request")) {
      var sData = this.EFilingEntity.requestData;
    }
    else {
      var sData = this.EFilingEntity.responseData;
    }
    //var sFileName = sType + "_" + new Date().toISOString() + ".xml";
    var sFileName = sType.concat(".xml");
    if (sData != undefined) {
      if (sData != null) {
        let file = new Blob([sData], { type: 'text/xml;charset=utf-8' });
        saveAs(file, sFileName);
      }
    }
  }
  getStates() {
    this.efilingService.getCourtStates()
      .subscribe(result => {
        if (result.is_success) {

          this.stateList = result.content;
          //this.stateList = this.stateList.filter(x => x.code.trim() == 'CA');
          //this.sele.setValue('CA');
        }
        else {
          console.error(result.message);
        }
      }, error => console.error(error));
  }
  getStatus() {
    this.efilingService.getStatus()
      .subscribe(result => {
        if (result.is_success) {

          this.statusList = result.content;

        }
        else {
          console.error(result.message);
        }
      }, error => console.error(error));
  }

  getCourts() {
    this.efilingService.getCourts()
      .subscribe(result => {
        if (result.is_success) {

          this.courtList = result.content;
          //this.stateList = this.stateList.filter(x => x.code.trim() == 'CA');
          //this.sele.setValue('CA');
        }
        else {
          console.error(result.message);
        }
      }, error => console.error(error));
  }

  gets(value) {

    alert(value);
  }
}
