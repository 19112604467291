export class User {
  id: number;
  passwordHash: string;
  firstName: string;
  lastName: string;
  token: string;
  userName: string;
  email: string;
  roleId: string;
  rolestatus: string;
  isDeactivated: string;
  department: string;
  message: string;
}
