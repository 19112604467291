import { Component, OnInit, Input } from '@angular/core';
import { EfilingService } from '../services/efiling.service';
import { debug } from 'util';
import { element } from '@angular/core/src/render3';



@Component({
  selector: 'app-daily-reconcilation',
  templateUrl: './daily-reconcilation.component.html',
  styleUrls: ['./daily-reconcilation.component.css']
})
export class DailyReconcilationComponent implements OnInit {
  @Input() styles: any = {};
  months: Months[];
  curYear: number;
  prevYear: number;
  ResultList: ResultDto;
  reportData: Months[];
  constructor(private efilingService: EfilingService, ) {

    this.curYear = 2020;
    this.prevYear = this.curYear - 1;
  }

  intializeMonths() {
    this.months = [
      { id: 1, name: 'JAN', status: 'deactive' },
      { id: 2, name: 'FEB', status: 'deactive' },
      { id: 3, name: 'MAR', status: 'deactive' },
      { id: 4, name: 'APR', status: 'deactive' },
      { id: 5, name: 'MAY', status: 'deactive' },
      { id: 6, name: 'JUN', status: 'deactive' },
      { id: 7, name: 'JUL', status: 'deactive' },
      { id: 8, name: 'AUG', status: 'deactive' },
      { id: 9, name: 'SEP', status: 'deactive' },
      { id: 10, name: 'OCT', status: 'deactive' },
      { id: 11, name: 'NOV', status: 'deactive' },
      { id: 12, name: 'DEC', status: 'deactive' },
    ];
  }

  ngOnInit() {
    this.color = 'red';
    this.styles = { 'backgroundColor': 'deactive', 'width': '30px', 'height': '30px', 'border': 'none' };
    this.intializeMonths();
    this.GetReportData(this.curYear);
  }
  color: string;

  GetReportData(year) {
    debugger;

    this.efilingService.GetReportData(year)
      .subscribe(result => {
        if (result.length!= 0) {
          // as EFilingDetailsDTO;
          for (let i = 0; i < result.length; i++) {
            if (result[i].status) {
              var a = this.months.find(x => x.id == result[i].month);
              a.status = "green";
            }
            else {
              var a = this.months.find(x => x.id == result[i].month);
              a.status = "red";
            }
          }
        } else {
          this.intializeMonths()
        }
        this.reportData = this.months;
      }, error => console.error(error));
  }

  curYearChange() {
    this.curYear = this.prevYear;
    this.prevYear = this.prevYear + 1;
    this.GetReportData(this.curYear);
  }
  prevYearChange() {
    this.GetReportData(this.curYear);

    this.curYear = this.prevYear;
    this.prevYear = this.prevYear - 1;
  }

}

export class Months {
  id: number;
  name: String;
  status: string;
}
export class ResultDto {
  months: number;
  status: boolean;


}
