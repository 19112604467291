import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { transition } from '@angular/animations';
import { Global as glb } from '../shared/global';
//const BASE_URL_WEBAPI = env.webApiBaseUri;
//const BASE_URL_WEBAPI = 'https://localhost:44371/api';
const BASE_URL_WEBAPI = glb.BASE_USER_ENDPOINT;

@Injectable({ providedIn: 'root' })
export class WebApiService {
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: false };

  constructor(private httpClient: HttpClient) { }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    //debugger;
    return this.httpClient.get(BASE_URL_WEBAPI + path, { params }).pipe(catchError(this.formatErrors));
  }

  public put(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .put(BASE_URL_WEBAPI + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: object = {}): Observable<any> {
    //debugger;

    return this.httpClient
      .post(BASE_URL_WEBAPI + path, JSON.parse(JSON.stringify(body)), this.options)
      .pipe(catchError(this.formatErrors));
    //.catch(this.handleError);
    //.pipe(catchError(this.formatErrors));


  }
  public postWithCookies(path: string, body: object = {}): Observable<any> {
    this.options.withCredentials = true;
    return this.httpClient
      .post(BASE_URL_WEBAPI + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public delete(path: string): Observable<any> {
    return this.httpClient.delete(BASE_URL_WEBAPI + path).pipe(catchError(this.formatErrors));
  }
  public Options(path: string): Observable<any> {
    return this.httpClient.options(BASE_URL_WEBAPI + path).pipe(catchError(this.formatErrors));
  }
  public formatErrors(error: any): Observable<any> {
    //return throwError(error.error);
    //const errorMsg = (error.message) ? error.message :
    //  error.status ? `${error.status} - ${error.statusText}` : 'Server error';

    //todojr: log exceptions...

    return Observable.throw(error.error);
  }
  public get_efm_AttorneyList(): Observable<any> {
    return this.httpClient.get("./assets/AttorneyList.json");
  }
}
