export class SearchDTO {
  confirmationNumber: string;
  orderNumber: number;
  caseTitle: string;
  statusCode: any[];
  statusCodeName: any[];
  stepType: string;
  createdFromDate: string;
  createdToDate: string;
  filerFullName: string;
  submittedFromDate: string;
  submittedToDate: string;
  filerEmailID: string;
  matterReferenceCode: string;
  clientReferenceCode: string;
  caseNumber: string;
  state: string;
  courtsCentral: string;
  email: string;
  courtProcFromDate: string;
  courtProcToDate: string;
  CourtProcessDate: string;
}

export class EFilingDetailsDTO {
  alnUserId: string;
  amountInControversy: string;
  attorneyCaseParticipantID: string;
  callbackEmailSent: string;
  caseCategoryCode: string;
  caseIsConfidential: string;
  caseNumber: string;
  caseTitle: string;
  caseTrackingID: string;
  caseTypeCode: string;
  citationDate: string;
  citationIdentification: string;
  clientMatterReferenceCode: string;
  clientReference: string;
  clientReferenceCode: string;
  conciergeCustomerCode: string;
  conditionallySealed: boolean;
  confirmationNumber: string;
  courtDivision: string;
  courtFirmGuid: string;
  courtUserGuid: string;
  createdFromDate: string;
  createdToDate: string;
  customerCode: string;
  dateTimeDeleted: string;
  dateTimeFiled: string;
  dateTimeReceived: string;
  deliverCourtesyCopy: string;
  feePaid: string;
  feeWaiverAppliesToThisOrder: string;
  filingAttorneyCode: string;
  filingAttorneyEmail: string;
  filingAttorneyHasChanged: false
  filingAttorneyVerb: string;
  firstComplaintID: string;
  governmentAgencyFeeWaiver: string;
  juriAmount: string;
  jurisAmountCode: string;
  locationCode: string;
  matterReferenceCode: string;
  messageToClerk: string;
  noFeeCase: string;
  noFeeSection: string;
  numberOfParcels: string;
  orderNumber: number;
  ownedBy: string;
  parentLocationName: string;
  statusCode: string;
  subDivision: string;
  submittedDocumentsCanBeDeleted: string;
  submittedFromDate: string;
  submittedToDate: string;
  thisIsANewCase: string;
  user: string;
  locationName: string;
  requestData: string;
  responseData: string;
  userid: string;
  FilerEmailID: string;
  username: string;
  FilerFullName: string;
  FirmCompany: string;
  isProcessed: boolean;
  message: string;
  ReceivedDate: string;
  ProcessedDate: string;
  //isFiler: string;

}


export class CourtStatesDTO {
  Code: string;
  Name: string;
  CountryName: string;

}
export class CourtsDTO {
  ID: number;
  Name: string;
  Description: string;
  EfilingStateID: number;
  CountryKey: string;
  CountryType: string;  
  EFilingUrl: string;

}
export class StatusDTO {
  id: number;
  code: string;
  description: string;
  efilingStateID: number;
  uIDisplayDescription: string;
  createdDateTime: string;
  

}

