import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, config } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../model/User';
import { Global } from '../shared/global';
import { Response } from 'selenium-webdriver/http';
import { isString, debug } from 'util';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  visible: boolean;
  AdminVisible: boolean;
  QAVisible: boolean;
  VendorVisible: boolean;
  Roleid: string;
  constructor(private http: HttpClient) {
    this.visible = false;
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  hide() { this.visible = false; }
  show() { this.visible = true; }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public login(username: string, password: string): Observable<string> {
    const params = new HttpParams().set('username', username).set('password', password);
    return this.http.get<string>(Global.BASE_USER_ENDPOINT + 'User/Login/', { params }).pipe(map(result => {
      if (result!="") {
        //this.Roleid = result[1];
        this.LeftMenu();
          // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('TokenInfo', JSON.stringify(result[0]));
        localStorage.setItem('UserInfo', result[1]);
        localStorage.setItem('LoginEmail', result[2]);
        localStorage.setItem('Roleid', "1");
        }
      return result;
    }));
  }
  LeftMenuLoading() {
    if (this.Roleid == undefined) {
      this.Roleid = this.getRole();
      this.LeftMenu();
    }
    else {
      //this.LeftMenu();
    }    
  }

  LeftMenu() {
    if (this.Roleid == "1") {
      this.AdminVisible = true;
      this.QAVisible = true;
      this.VendorVisible = true;
    }
    else if (this.Roleid == "2") {
      this.AdminVisible = false;
      this.QAVisible = true;
      this.VendorVisible = false;
    }
    else if (this.Roleid == "3") {
      this.AdminVisible = false;
      this.QAVisible = false;
      this.VendorVisible = true;
    }
    else {
      this.AdminVisible = false;
      this.QAVisible = false;
      this.VendorVisible = false;
    }
  }
  errorHandler(error: Response) {
    console.log(error);
  }
  public IsAuthenticated() {
    return this.getToken() !== null;
  }
  StoreToken(token: string) {
    return localStorage.setItem('TokenInfo', token);
  }
  getToken() {
    return localStorage.getItem('TokenInfo');
  }
  public getUser() {
    return localStorage.getItem('UserInfo');
  }
  public getRole() {
    return localStorage.getItem('Roleid');
  }
  logout() {
    localStorage.removeItem('TokenInfo');
    localStorage.removeItem('UserInfo');
    localStorage.removeItem('Roleid');
  }
}
