/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./revenue-report.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./revenue-report.component";
var styles_RevenueReportComponent = [i0.styles];
var RenderType_RevenueReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RevenueReportComponent, data: {} });
export { RenderType_RevenueReportComponent as RenderType_RevenueReportComponent };
export function View_RevenueReportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" revenue-report works!\n"]))], null, null); }
export function View_RevenueReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-revenue-report", [], null, null, null, View_RevenueReportComponent_0, RenderType_RevenueReportComponent)), i1.ɵdid(1, 114688, null, 0, i2.RevenueReportComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RevenueReportComponentNgFactory = i1.ɵccf("app-revenue-report", i2.RevenueReportComponent, View_RevenueReportComponent_Host_0, {}, {}, []);
export { RevenueReportComponentNgFactory as RevenueReportComponentNgFactory };
