import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxTreeViewModule, DxSelectBoxModule, DxAutocompleteModule, DxTemplateModule} from 'devextreme-angular'
import { RouterModule, Routes } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatAutocompleteModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule} from '@angular/material';
import { FileDropModule } from 'ngx-file-drop';
import { ResizableModule } from 'angular-resizable-element';
import { HomeComponent } from './home/home.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DataTablesModule } from 'angular-datatables'
import { BsModalModule } from 'ng2-bs3-modal';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule } from 'angular-notifier';
import { httpInterceptor } from './Interceptor/httpInterceptor';
//import { ErrorInterceptor } from './Interceptor/errorInterceptor';
import { AuthorizationCheck } from './Services/authorizationCheck';
import { AuthenticationService } from './Services/authentication.service';
import { SearchesComponent } from './Searches/Searches.component';
import { EfilingService } from './services/efiling.service';
import { WebApiService } from './services/web-api.service';
import { PagerService } from './services/pager.service';
import { DailyReconcilationComponent } from './daily-reconcilation/daily-reconcilation.component';
import { RevenueReportComponent } from './revenue-report/revenue-report.component';
import { MatListModule, MatSelectModule, MatOptionModule, MatButtonModule } from '@angular/material';

const appRoutes: Routes = [

  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthorizationCheck] },
  { path: 'Searches', component: SearchesComponent, canActivate: [AuthorizationCheck] },
  { path: 'daily-reconcilation', component: DailyReconcilationComponent, canActivate: [AuthorizationCheck] },
  { path: 'RevenueReport', component: RevenueReportComponent, canActivate: [AuthorizationCheck] },


];

@NgModule({
  declarations: [
    LoginComponent, AppComponent, HomeComponent, NavMenuComponent, SearchesComponent, DailyReconcilationComponent, RevenueReportComponent
  ],
  imports: [
    BrowserModule,
    NotifierModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    DataTablesModule,
    BsModalModule,ResizableModule,
    MatAutocompleteModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule,
    DxTreeViewModule, DxSelectBoxModule, MatAutocompleteModule, DxAutocompleteModule, DxTemplateModule, BrowserAnimationsModule,
    MatInputModule, FileDropModule,
    MatSelectModule,
    MatListModule,
    MatButtonModule,
    RouterModule.forRoot(appRoutes, { useHash: true })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
    AuthorizationCheck, AuthenticationService, EfilingService, WebApiService, PagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
