import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService} from '../services/authentication.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  constructor(private spinner: NgxSpinnerService, private Nav: AuthenticationService) { }
  ngOnInit() {
    this.Nav.show();
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }
}
