import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable()
export class AuthorizationCheck implements CanActivate {

  constructor(private router: Router, private spinner: NgxSpinnerService, private userService: AuthenticationService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //If token data exist, user may login to application
    //debugger;
    this.userService.LeftMenuLoading();
    if (localStorage.getItem('TokenInfo')) {
      return true;
    }   
    // otherwise redirect to login page with the return url
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    this.spinner.hide();
    return false;   
  }
}
