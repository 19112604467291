import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global as glb } from '../shared/global';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
//const BASE_URL_WEBAPI = env.webApiBaseUri;
//const BASE_URL_WEBAPI = 'https://localhost:44371/api';
var BASE_URL_WEBAPI = glb.BASE_USER_ENDPOINT;
var WebApiService = /** @class */ (function () {
    function WebApiService(httpClient) {
        this.httpClient = httpClient;
        this.options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: false };
    }
    WebApiService.prototype.get = function (path, params) {
        if (params === void 0) { params = new HttpParams(); }
        //debugger;
        return this.httpClient.get(BASE_URL_WEBAPI + path, { params: params }).pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.put = function (path, body) {
        if (body === void 0) { body = {}; }
        return this.httpClient
            .put(BASE_URL_WEBAPI + path, JSON.stringify(body), this.options)
            .pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.post = function (path, body) {
        //debugger;
        if (body === void 0) { body = {}; }
        return this.httpClient
            .post(BASE_URL_WEBAPI + path, JSON.parse(JSON.stringify(body)), this.options)
            .pipe(catchError(this.formatErrors));
        //.catch(this.handleError);
        //.pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.postWithCookies = function (path, body) {
        if (body === void 0) { body = {}; }
        this.options.withCredentials = true;
        return this.httpClient
            .post(BASE_URL_WEBAPI + path, JSON.stringify(body), this.options)
            .pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.delete = function (path) {
        return this.httpClient.delete(BASE_URL_WEBAPI + path).pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.Options = function (path) {
        return this.httpClient.options(BASE_URL_WEBAPI + path).pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.formatErrors = function (error) {
        //return throwError(error.error);
        //const errorMsg = (error.message) ? error.message :
        //  error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        //todojr: log exceptions...
        return Observable.throw(error.error);
    };
    WebApiService.prototype.get_efm_AttorneyList = function () {
        return this.httpClient.get("./assets/AttorneyList.json");
    };
    WebApiService.ngInjectableDef = i0.defineInjectable({ factory: function WebApiService_Factory() { return new WebApiService(i0.inject(i1.HttpClient)); }, token: WebApiService, providedIn: "root" });
    return WebApiService;
}());
export { WebApiService };
