import { Component, Input, Renderer, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl:'./nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
  
})
export class NavMenuComponent {
  isExpanded = false;
  isUserLoggedIn: boolean;
  down = false;
  up = true;

  router: Router;
  constructor(public Auth: AuthenticationService, private render: Renderer, private _router: Router) { }
  collapse() {
    this.isExpanded = false;
  }
  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  arrowChange() {
    this.down = !this.down;
    this.up = !this.up;
    this.isExpanded = !this.isExpanded;

  }
  toggleMenu() {
    $('#menu-toggle').click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("active");
    });
  }
}
