import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Global } from '../shared/global';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http) {
        this.http = http;
        this.visible = false;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    AuthenticationService.prototype.hide = function () { this.visible = false; };
    AuthenticationService.prototype.show = function () { this.visible = true; };
    Object.defineProperty(AuthenticationService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.login = function (username, password) {
        var _this = this;
        var params = new HttpParams().set('username', username).set('password', password);
        return this.http.get(Global.BASE_USER_ENDPOINT + 'User/Login/', { params: params }).pipe(map(function (result) {
            if (result != "") {
                //this.Roleid = result[1];
                _this.LeftMenu();
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('TokenInfo', JSON.stringify(result[0]));
                localStorage.setItem('UserInfo', result[1]);
                localStorage.setItem('LoginEmail', result[2]);
                localStorage.setItem('Roleid', "1");
            }
            return result;
        }));
    };
    AuthenticationService.prototype.LeftMenuLoading = function () {
        if (this.Roleid == undefined) {
            this.Roleid = this.getRole();
            this.LeftMenu();
        }
        else {
            //this.LeftMenu();
        }
    };
    AuthenticationService.prototype.LeftMenu = function () {
        if (this.Roleid == "1") {
            this.AdminVisible = true;
            this.QAVisible = true;
            this.VendorVisible = true;
        }
        else if (this.Roleid == "2") {
            this.AdminVisible = false;
            this.QAVisible = true;
            this.VendorVisible = false;
        }
        else if (this.Roleid == "3") {
            this.AdminVisible = false;
            this.QAVisible = false;
            this.VendorVisible = true;
        }
        else {
            this.AdminVisible = false;
            this.QAVisible = false;
            this.VendorVisible = false;
        }
    };
    AuthenticationService.prototype.errorHandler = function (error) {
        console.log(error);
    };
    AuthenticationService.prototype.IsAuthenticated = function () {
        return this.getToken() !== null;
    };
    AuthenticationService.prototype.StoreToken = function (token) {
        return localStorage.setItem('TokenInfo', token);
    };
    AuthenticationService.prototype.getToken = function () {
        return localStorage.getItem('TokenInfo');
    };
    AuthenticationService.prototype.getUser = function () {
        return localStorage.getItem('UserInfo');
    };
    AuthenticationService.prototype.getRole = function () {
        return localStorage.getItem('Roleid');
    };
    AuthenticationService.prototype.logout = function () {
        localStorage.removeItem('TokenInfo');
        localStorage.removeItem('UserInfo');
        localStorage.removeItem('Roleid');
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
