/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./nav-menu/nav-menu.component.ngfactory";
import * as i3 from "./nav-menu/nav-menu.component";
import * as i4 from "./services/authentication.service";
import * as i5 from "@angular/router";
import * as i6 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i7 from "ngx-spinner";
import * as i8 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-menu", [], null, null, null, i2.View_NavMenuComponent_0, i2.RenderType_NavMenuComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavMenuComponent, [i4.AuthenticationService, i1.Renderer, i5.Router], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngx-spinner", [["bdColor", "rgba(51,51,51,0.8)"], ["color", "#fff"], ["size", "medium"], ["type", "ball-scale-multiple"]], null, null, null, i6.View_NgxSpinnerComponent_0, i6.RenderType_NgxSpinnerComponent)), i1.ɵdid(3, 770048, null, 0, i7.NgxSpinnerComponent, [i7.NgxSpinnerService], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [["style", "font-size: 20px; color: white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."])), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "rgba(51,51,51,0.8)"; var currVal_1 = "medium"; var currVal_2 = "#fff"; var currVal_3 = "ball-scale-multiple"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i8.AppComponent, [i4.AuthenticationService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
